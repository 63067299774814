'use strict'

angular
	.module('cb.core.login', [])
	.controller('LoginController', ["$scope", "$rootScope", "$global", "$location", "$window", "SessionService", "IpadService", "NotificationService", function ($scope, $rootScope, $global, $location, $window, SessionService, IpadService, NotificationService) {
		$global.set('fullscreen', true);

		$scope.info = "Version " + angular.version.full;
		$scope.numberOfLoginAttempts = 0;
		$scope.lastUsernameAttempt = '';
		$scope.YearDateFunction = function () {
			var currentdate = new Date();
			return currentdate.getFullYear();
		}
		$scope.init = function () {
			SessionService.testCall();
		};

		$scope.$on('$destroy', function () {
			$global.set('fullscreen', false);
		});

		$scope.userDestroy = function () {
			IpadService.userLogout();
		}

		$scope.IsOfflineLoginAvailable = function(){
			return (!($rootScope.onLineFlag) && (localStorage.getItem('userId') != null))
		}

		$scope.debugTarget = $location.search()['debugtarget'];
		
		if ($scope.debugTarget != undefined) {
			$scope.SingleSignin = true;
			$scope.loginPromise = SessionService.attemptDebugSingleSignIn($scope.debugTarget);
			$scope.loginPromise.then(function (output) {

				if (output.data == 'true') {
					$scope.numberOfLoginAttempts++;
					if ($scope.lastUsernameAttempt != $scope.email) {
						$scope.lastUsernameAttempt = $scope.email;
						$scope.numberOfLoginAttempts = 1;
					}
					if ($scope.numberOfLoginAttempts >= 3)
						NotificationService.alert('Failed Login Attempt', 'If this Username is valid, the account has been locked. Please contact an administrator.', 'error', {});
					else
						NotificationService.alert('Failed Login Attempt', 'The Username and/or Password is wrong. Please try again.', 'error', {});
				}
				else {
					$scope.numberOfLoginAttempts = 0;
					$window.localStorage.cbToken = output.data.Token;
					SessionService.setCredentials();
					$rootScope.$broadcast('loggedInSS', true);
				}
			});
		}

		$scope.target = $location.search()['target'];

		if ($scope.target != undefined) {
			$scope.SingleSignin = true;
			$scope.loginPromise = SessionService.attemptSingleSignIn($scope.target);
			$scope.loginPromise.then(function (output) {
				if (output.data == 'true') {
					$scope.numberOfLoginAttempts++;
					if ($scope.lastUsernameAttempt != $scope.email) {
						$scope.lastUsernameAttempt = $scope.email;
						$scope.numberOfLoginAttempts = 1;
					}
					if ($scope.numberOfLoginAttempts >= 3)
						NotificationService.alert('Failed Login Attempt', 'If this Username is valid, the account has been locked. Please contact an administrator.', 'error', {});
					else
						NotificationService.alert('Failed Login Attempt', 'The Username and/or Password is wrong. Please try again.', 'error', {});
				}
				else {
					$scope.numberOfLoginAttempts = 0;
					$window.localStorage.cbToken = output.data.Token;
					SessionService.setCredentials();
					$rootScope.$broadcast('loggedInSS', true);
				}
			});
		}

		// Can we add some bypass logic for offline use?
		$scope.submit = function () {
			$scope.SingleSignin = false;
			if ($rootScope.onLineFlag){
			$scope.loginPromise = SessionService.attemptLogin($scope.email, $scope.password);
				$scope.loginPromise.then(function (output) {
					if (output.data == 'true') {
						$scope.numberOfLoginAttempts++;
						if ($scope.lastUsernameAttempt != $scope.email) {
							$scope.lastUsernameAttempt = $scope.email;
							$scope.numberOfLoginAttempts = 1;
						}
						if ($scope.numberOfLoginAttempts >= 3)
							NotificationService.alert('Failed Login Attempt', 'If this Username is valid, the account has been locked. Please contact an administrator.', 'error', {});
						else
							NotificationService.alert('Failed Login Attempt', 'The Username and/or Password is wrong. Please try again.', 'error', {});
					}
					else {
						$scope.numberOfLoginAttempts = 0;
						$window.localStorage.cbToken = output.data.Token;
						SessionService.setCredentials();
						$rootScope.$broadcast('loggedIn', true);
					}
				});
			}
		};
		$scope.offlineLogin = function () {
			setTimeout($scope.userDestroy, 1000 * 60 * 60 * 24); // set userId entry in localStorage to be destroy after 24 Hours
			const login =  IpadService.getUserData();
			login.then(function(data){
				$window.localStorage.cbToken = data.Token;
				SessionService.setCredentials();
				$rootScope.$broadcast('loggedIn', true);
			})
		}
	}])
